import PropTypes from "prop-types";
import styles from "../../../scss/components/general/LabelBox.module.scss";

const LabelBox = ({ label, value, maxlength, count }) => (
  <div className={styles.labelBox}>
    <span>{label}</span>
    {count && <span>{`${value.length} ${maxlength && `/${maxlength}`}`}</span>}
  </div>
);

LabelBox.defaultProps = {
  label: "",
  value: "",
  maxlength: "",
  count: false,
};

LabelBox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  maxlength: PropTypes.string,
  count: PropTypes.bool,
};

export default LabelBox;
