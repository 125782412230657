import PropTypes from "prop-types";
import { withTranslation } from "next-i18next";
import Wrapper from "../../layouts/Wrapper";
import ForgotPasswordFirst from "./ForgotPasswordFirst";
import ForgotPasswordConfirm from "./ForgotPasswordConfirm";
import PasswordReset from "./PasswordReset";

const ForgotPassword = ({
  t,
  forgotPassView,
  handleForgotPass,
  submitForgotPass,
  forgotForm,
  backToLogin,
  newPassword,
  handleNewPassword,
  submitSavePass,
}) => (
  <Wrapper styleWrapper="wrapperForm">
    {forgotPassView.forgotPassFirst && (
      <ForgotPasswordFirst
        header={t("forgottenPassword")}
        label={t("pleaseEnterEmail")}
        onChange={handleForgotPass}
        placeholder={t("pleaseEnterEmail")}
        submitForgotPass={submitForgotPass}
        textBtn={t("enter")}
        value={forgotForm}
      />
    )}
    {forgotPassView.forgotPassSecond && (
      <ForgotPasswordConfirm
        backToLogin={backToLogin}
        confirm={t("submitInstruction")}
        textBtn={t("backToLogin")}
        header={t("forgottenPassword")}
      />
    )}
    {forgotPassView.forgotPassReset && (
      <PasswordReset
        header={t("resetYourPass")}
        labelConfirm={t("confirmPass")}
        labelpass={t("enterPass")}
        onChange={handleNewPassword}
        submitSavePass={submitSavePass}
        textBtn={t("save")}
        value={newPassword}
      />
    )}
  </Wrapper>
);

ForgotPassword.defaultProps = {
  forgotPassView: {},
  newPassword: {},
  handleForgotPass: () => {},
  submitForgotPass: () => {},
  backToLogin: () => {},
  handleNewPassword: () => {},
  submitSavePass: () => {},
  forgotForm: "",
};

ForgotPassword.propTypes = {
  t: PropTypes.func.isRequired,
  forgotPassView: PropTypes.shape({
    forgotPassFirst: PropTypes.bool,
    forgotPassSecond: PropTypes.bool,
    forgotPassReset: PropTypes.bool,
  }),
  handleForgotPass: PropTypes.func,
  submitForgotPass: PropTypes.func,
  backToLogin: PropTypes.func,
  handleNewPassword: PropTypes.func,
  submitSavePass: PropTypes.func,
  forgotForm: PropTypes.string,
  newPassword: PropTypes.shape({}),
};

export default withTranslation("loginPage")(ForgotPassword);
