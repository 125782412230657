import PropTypes from "prop-types";
import PrismLink from "./PrismLink";
import styles from "scss/components/general/LabelLink.module.scss";

const LabelLink = ({ label, href, typeStyle, text, styleLabel }) => (
  <div className={styles.createAccount}>
    <span className={`${styles.label} ${styleLabel && styles[styleLabel]}`}>{label}</span>
    <PrismLink href={href} typeStyle={typeStyle}>
      {text}
    </PrismLink>
  </div>
);

LabelLink.defaultProps = {
  label: "",
  href: "",
  typeStyle: "",
  text: "",
  styleLabel: "",
};

LabelLink.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string,
  typeStyle: PropTypes.string,
  text: PropTypes.string,
  styleLabel: PropTypes.string,
};

export default LabelLink;
