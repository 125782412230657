import { useState } from "react";
import { withTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useMutation } from "@apollo/client";
import LoginForm from "../components/login/LoginForm";
import { getEmptyFormDataBool, setToLocalStorage } from "../utils/storageWorks";
import ForgotPassword from "../components/login/ForgotPassword";
import Authentication from "../utils/authentication";
import storageWorks from "../constants/storageWorks";
import getCookie from "../utils/cookieUtils";

import useDataPortal from "hooks/useDataPortal";

import styles from "../../scss/components/loginPage/Login.module.scss";
import request from "../graphql/serverRequest";

const Login = () => {
  const router = useRouter();
  const { loginUser, setAuthStatus } = Authentication();

  const portal = useDataPortal();

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
    remember: false,
  });

  const [loginView, setLoginView] = useState(true);
  const [forgotPassView, setForgotPassView] = useState({
    forgotPassFirst: false,
    forgotPassSecond: false,
    forgotPassReset: false,
  });

  const [emailForgotPass, setEmailForgotPass] = useState("");
  const [newPassword, setNewPassword] = useState({
    password: "",
    confirm: "",
  });

  const [statusResetPass, setStatusResetPass] = useState(false);
  const [error, setError] = useState(null);
  const [forgotPwdMutation] = useMutation(request.ForgotPassword);
  const [loading, setLoading] = useState(false);

  const submitLogin = (event) => {
    event.preventDefault();
    setLoading(true);
    loginUser(loginForm)
      .then(() => {
        setAuthStatus(true);
        setLoading(false);
        // There is a bug with pricing
        // The publications are fetched before you log in
        // when you log in, the pricing for restricted packages isn't updated
        // redirecting to the home page to refetch the publications for now
        router.push("/");
        // if (pastHref) {
        //   router.push(lastActiveHref());
        // } else {
        //   router.push("/");
        // }
      })
      .catch((e) => {
        setError(e.message);
        setLoading(false);
      });
  };

  const changeFormHandler = (event) => {
    const { name, value, id } = event.currentTarget;
    if (id === storageWorks.remember) {
      const { remember } = loginForm;
      setLoginForm({ ...loginForm, [id]: !remember });
      setToLocalStorage(storageWorks.remember, !remember);
    } else {
      setLoginForm({ ...loginForm, [name]: value });
    }
  };

  const handleForgotPass = (event) => {
    setEmailForgotPass(event.target.value);
  };

  const handleNewPassword = (event) => {
    setNewPassword({ ...newPassword, [event.target.name]: event.target.value });
  };

  const openForgotForm = () => {
    setLoginView(false);
    setForgotPassView({ ...forgotPassView, forgotPassFirst: true });
  };

  const submitForgotPass = (event) => {
    event.preventDefault();
    const newData = getEmptyFormDataBool(forgotPassView);
    setForgotPassView({ ...newData, forgotPassSecond: true });
    forgotPwdMutation({
      variables: {
        input: {
          email: emailForgotPass,
        },
        portalId: portal.id,
        host: window?.location?.origin,
      },
    });
  };

  const backToLogin = () => {
    const newData = getEmptyFormDataBool(forgotPassView);
    setForgotPassView(newData);
    setStatusResetPass(true);
    setLoginView(true);
  };

  const submitSavePass = () => {
    backToLogin();
  };

  return (
    <div className={styles.container}>
      {loginView && (
        <LoginForm
          submitLogin={submitLogin}
          changeFormHandler={changeFormHandler}
          loginForm={loginForm}
          openForgotForm={openForgotForm}
          statusResetPass={statusResetPass}
          error={error}
          loading={loading}
        />
      )}
      {!loginView && (
        <ForgotPassword
          forgotPassView={forgotPassView}
          handleForgotPass={handleForgotPass}
          submitForgotPass={submitForgotPass}
          forgotForm={emailForgotPass}
          backToLogin={backToLogin}
          newPassword={newPassword}
          handleNewPassword={handleNewPassword}
          submitSavePass={submitSavePass}
        />
      )}
    </div>
  );
};

export const getServerSideProps = async ({ req }) => {
  if (getCookie(req.headers.cookie, "auth")) {
    return {
      redirect: {
        destination: "/",
        permanent: false,
      },
    };
  }

  return {
    props: {
      namespacesRequired: ["common", "loginPage"],
    },
  };
};

export default withTranslation("common")(Login);
