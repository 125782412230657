import PropTypes from "prop-types";
import InputBox from "../UI/InputBox";
import Button from "../UI/Button";

import styles from "../../../scss/components/loginPage/PasswordReset.module.scss";

const PasswordReset = ({ header, submitSavePass, labelpass, labelConfirm, onChange, value, textBtn }) => (
  <div className={styles.container}>
    <h3>{header}</h3>
    <form className={styles.form} onSubmit={submitSavePass}>
      <InputBox label={labelpass} onChange={onChange} name="password" value={value.password} type="password" />
      <InputBox label={labelConfirm} onChange={onChange} name="confirm" value={value.confirm} type="password" />
      <Button disabled={value.password === "" || value.confirm === ""} typeBtn={false} color="buttonLogIn">
        {textBtn}
      </Button>
    </form>
  </div>
);

PasswordReset.defaultProps = {
  header: "",
  labelpass: "",
  labelConfirm: "",
  textBtn: "",
  submitSavePass: () => {},
  onChange: () => {},
  value: {},
};

PasswordReset.propTypes = {
  header: PropTypes.string,
  labelpass: PropTypes.string,
  labelConfirm: PropTypes.string,
  textBtn: PropTypes.string,
  submitSavePass: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    password: PropTypes.string,
    confirm: PropTypes.string,
  }),
};

export default PasswordReset;
