import PropTypes from "prop-types";
import Button from "../UI/Button";
import styles from "../../../scss/components/loginPage/ForgotPasswordConfirm.module.scss";

const ForgotPasswordConfirm = ({ header, textBtn, backToLogin, confirm }) => (
  <div className={styles.container}>
    <h3>{header}</h3>
    <div className={styles.btnBox}>
      <span className={styles.textConfirm}>{confirm}</span>
      <Button onClick={backToLogin} color="buttonBAackToLogIn">
        {textBtn}
      </Button>
    </div>
  </div>
);

ForgotPasswordConfirm.defaultProps = {
  header: "",
  confirm: "",
  textBtn: "",
  backToLogin: () => {},
};

ForgotPasswordConfirm.propTypes = {
  backToLogin: PropTypes.func,
  header: PropTypes.string,
  textBtn: PropTypes.string,
  confirm: PropTypes.string,
};

export default ForgotPasswordConfirm;
